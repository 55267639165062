import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { Router, CanActivate } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { User } from '../models/user.model';
import { LogService } from '../log/log.service';
import { BaseRequest } from './common.api';

@Injectable()
export class CCService {
  private baseUrl = environment.apigateway_url;
  private apikey = environment.apigateway_key ? environment.apigateway_key : '';
  private accesscode;

  protected httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
  };

  public currentUser: User;

  constructor(
    protected http: HttpClient,
    protected router: Router,
    protected logger: LogService,
    public auth: AuthService,
  ) {
    auth.currentUser.subscribe(u => {
      if (u) {
        this.currentUser = u;
        this.accesscode = u.accessCode; // this.auth.getToken();
        this.httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'X-CC-AccessCode': this.accesscode,
            'X-CC-AppId': this.apikey,
          }),
        };
      }
    });
  }

  public getUrl(url: string, module: string): string {
    const full = this.baseUrl + module + url;
    this.logger.trace(`get url: ${full}`, environment);
    return full;
  }

  // HTTp Get
  public get<T>(url: string): Observable<T> {
    return this.http.get<T>(url, this.httpOptions).pipe(
      tap(_ => this.logger.trace(`get api url=${url}`)),
      catchError(this.handleError<T>(`get api url=${url}`)),
    );
  }

  // HTTP Post
  public post<T>(url: string, postData: any): Observable<T> {
    if (postData) {
      postData.UserId = this.currentUser ? this.currentUser.UserId : 0;
      postData.CustomerId = this.currentUser ? this.currentUser.CustomerId : 0;
    }
    return this.http.post<T>(url, postData, this.httpOptions).pipe(
      tap(_ => this.logger.trace(`post to ${url}, w/`, `data: ${postData}`)),
      catchError(this.handleError<T>(`post to ${url}`)),
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      this.logger.error(error); // log to console instead
      // TODO: better job of transforming error for user consumption
      this.logger.error(`${operation} failed: ${error.message}`);
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
