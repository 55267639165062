import { Injectable } from '@angular/core';
import { TranslationPipe } from '../pipe/translationPipe';

@Injectable()
export class TranalateService {
  constructor(private translater: TranslationPipe) {}

  stateTranslate(states) {
    for (let i = 0; i < states.length; i++) {
      switch (states[i].code) {
        case 'AL':
          states[i].name = this.translater.transform('ALABAMA');
          break;
        case 'AK':
          states[i].name = this.translater.transform('ALASKA');
          break;
        case 'AS':
          states[i].name = this.translater.transform('AMERICAN_SAMOA');
          break;
        case 'AZ':
          states[i].name = this.translater.transform('ARIZONA');
          break;
        case 'AR':
          states[i].name = this.translater.transform('ARKANSAS');
          break;
        case 'CA':
          states[i].name = this.translater.transform('CALIFORNIA');
          break;
        case 'CO':
          states[i].name = this.translater.transform('COLORADO');
          break;
        case 'CT':
          states[i].name = this.translater.transform('CONNECTICUT');
          break;
        case 'DE':
          states[i].name = this.translater.transform('DELAWARE');
          break;
        case 'DC':
          states[i].name = this.translater.transform('DISTRICT_OF_COLUMBIA');
          break;
        case 'FM':
          states[i].name = this.translater.transform('FEDERATED_STATES_OF_MICRONESIA');
          break;
        case 'FL':
          states[i].name = this.translater.transform('FLORIDA');
          break;
        case 'GA':
          states[i].name = this.translater.transform('GEORGIA');
          break;
        case 'GU':
          states[i].name = this.translater.transform('GUAM');
          break;
        case 'HI':
          states[i].name = this.translater.transform('HAWAII');
          break;
        case 'ID':
          states[i].name = this.translater.transform('IDAHO');
          break;
        case 'IL':
          states[i].name = this.translater.transform('ILLINOIS');
          break;
        case 'IN':
          states[i].name = this.translater.transform('INDIANA');
          break;
        case 'IA':
          states[i].name = this.translater.transform('IOWA');
          break;
        case 'KS':
          states[i].name = this.translater.transform('KANSAS');
          break;
        case 'KY':
          states[i].name = this.translater.transform('KENTUCKY');
          break;
        case 'LA':
          states[i].name = this.translater.transform('LOUISIANA');
          break;
        case 'ME':
          states[i].name = this.translater.transform('MAINE');
          break;
        case 'MH':
          states[i].name = this.translater.transform('MARSHALL_ISLANDS');
          break;
        case 'MD':
          states[i].name = this.translater.transform('MARYLAND');
          break;
        case 'MA':
          states[i].name = this.translater.transform('MASSACHUSETTS');
          break;
        case 'MI':
          states[i].name = this.translater.transform('MICHIGAN');
          break;
        case 'MN':
          states[i].name = this.translater.transform('MINNESOTA');
          break;
        case 'MS':
          states[i].name = this.translater.transform('MISSISSIPPI');
          break;
        case 'MO':
          states[i].name = this.translater.transform('MISSOURI');
          break;
        case 'MT':
          states[i].name = this.translater.transform('MONTANA');
          break;
        case 'NE':
          states[i].name = this.translater.transform('NEBRASKA');
          break;
        case 'NV':
          states[i].name = this.translater.transform('NEVADA');
          break;
        case 'NH':
          states[i].name = this.translater.transform('NEW_HAMPSHIRE');
          break;
        case 'NJ':
          states[i].name = this.translater.transform('NEW_JERSEY');
          break;
        case 'NM':
          states[i].name = this.translater.transform('NEW_MEXICO');
          break;
        case 'NY':
          states[i].name = this.translater.transform('NEW_YORK');
          break;
        case 'NC':
          states[i].name = this.translater.transform('NORTH_CAROLINA');
          break;
        case 'ND':
          states[i].name = this.translater.transform('NORTH_DAKOTA');
          break;
        case 'MP':
          states[i].name = this.translater.transform('NORTHERN_MARIANA_ISLANDS');
          break;
        case 'OH':
          states[i].name = this.translater.transform('OHIO');
          break;
        case 'OK':
          states[i].name = this.translater.transform('OKLAHOMA');
          break;
        case 'OR':
          states[i].name = this.translater.transform('OREGON');
          break;
        case 'PW':
          states[i].name = this.translater.transform('PALAU');
          break;
        case 'PA':
          states[i].name = this.translater.transform('PENNSYLVANIA');
          break;
        case 'PR':
          states[i].name = this.translater.transform('PUERTO_RICO');
          break;
        case 'RI':
          states[i].name = this.translater.transform('RHODE_ISLAND');
          break;
        case 'SC':
          states[i].name = this.translater.transform('SOUTH_CAROLINA');
          break;
        case 'SD':
          states[i].name = this.translater.transform('SOUTH_DAKOTA');
          break;
        case 'TN':
          states[i].name = this.translater.transform('TENNESSEE');
          break;
        case 'TX':
          states[i].name = this.translater.transform('TEXAS');
          break;
        case 'UT':
          states[i].name = this.translater.transform('UTAH');
          break;
        case 'VT':
          states[i].name = this.translater.transform('VERMONT');
          break;
        case 'VI':
          states[i].name = this.translater.transform('VIRGIN_ISLANDS');
          break;
        case 'VA':
          states[i].name = this.translater.transform('VIRGINIA');
          break;
        case 'WA':
          states[i].name = this.translater.transform('WASHINGTON');
          break;
        case 'WV':
          states[i].name = this.translater.transform('WEST_VIRGINIA');
          break;
        case 'WI':
          states[i].name = this.translater.transform('WISCONSIN');
          break;
        case 'WY':
          states[i].name = this.translater.transform('WYOMING');
          break;
        default:
          break;
      }
    }
    return states;
  }

  timezoneTranslate(timezone) {
    for (let i = 0; i < timezone.length; i++) {
      switch (timezone[i].code) {
        case 'Dateline Standard Time':
          timezone[i].name = this.translater.transform('(UTC-12:00)INTERNATIONAL_DATE_LINE_WEST');
          break;
        case 'UTC-11':
          timezone[i].name = this.translater.transform('(UTC-11:00)COORDINATED_UNIVERSAL_TIME-11');
          break;
        case 'Hawaiian Standard Time':
          timezone[i].name = this.translater.transform('(UTC-10:00)HAWAII');
          break;
        case 'Alaskan Standard Time':
          timezone[i].name = this.translater.transform('(UTC-09:00)ALASKA');
          break;
        case 'Pacific Standard Time (Mexico)':
          timezone[i].name = this.translater.transform('(UTC-08:00)BAJA_CALIFORNIA');
          break;
        case 'Pacific Standard Time':
          timezone[i].name = this.translater.transform('(UTC-08:00)PACIFIC_TIME(US&CANADA)');
          break;
        case 'US Mountain Standard Time':
          timezone[i].name = this.translater.transform('(UTC-07:00)ARIZONA');
          break;
        case 'Mountain Standard Time (Mexico)':
          timezone[i].name = this.translater.transform('(UTC-07:00)CHIHUAHUA_LA_PAZ_MAZATLAN');
          break;
        case 'Mountain Standard Time':
          timezone[i].name = this.translater.transform('(UTC-07:00)MOUNTAIN_TIME(US&CANADA)');
          break;
        case 'Central America Standard Time':
          timezone[i].name = this.translater.transform('(UTC-06:00)CENTRAL_AMERICA');
          break;
        case 'Central Standard Time':
          timezone[i].name = this.translater.transform('(UTC-06:00)CENTRAL_TIME(US&CANADA)');
          break;
        case 'Central Standard Time (Mexico)':
          timezone[i].name = this.translater.transform(
            '(UTC-06:00)GUADALAJARA_MEXICO_CITY_MONTERREY',
          );
          break;
        case 'Canada Central Standard Time':
          timezone[i].name = this.translater.transform('(UTC-06:00)SASKATCHEWAN');
          break;
        case 'SA Pacific Standard Time':
          timezone[i].name = this.translater.transform('(UTC-05:00)BOGOTA_LIMA_QUITO_RIO_BRANCO');
          break;
        case 'Eastern Standard Time':
          timezone[i].name = this.translater.transform('(UTC-05:00)EASTERN_TIME(US&Canada)');
          break;
        case 'US Eastern Standard Time':
          timezone[i].name = this.translater.transform('(UTC-05:00)INDIANA(EAST)');
          break;
        case 'Venezuela Standard Time':
          timezone[i].name = this.translater.transform('(UTC-04:30)CARACAS');
          break;
        case 'Paraguay Standard Time':
          timezone[i].name = this.translater.transform('(UTC-04:00)ASUNCION');
          break;
        case 'Atlantic Standard Time':
          timezone[i].name = this.translater.transform('(UTC-04:00)ATLANTIC_TIME(CANADA)');
          break;
        case 'Central Brazilian Standard Time':
          timezone[i].name = this.translater.transform('(UTC-04:00)CUIABA');
          break;
        case 'SA Western Standard Time':
          timezone[i].name = this.translater.transform(
            '(UTC-04:00)GEORGETOWN_LA_PAZ_MANAUS_SAN_JUAN',
          );
          break;
        case 'Pacific SA Standard Time':
          timezone[i].name = this.translater.transform('(UTC-04:00)SANTIAGO');
          break;
        case 'Newfoundland Standard Time':
          timezone[i].name = this.translater.transform('(UTC-03:30)NEWFOUNDLAND');
          break;
        case 'E. South America Standard Time':
          timezone[i].name = this.translater.transform('(UTC-03:00)BRASILIA');
          break;
        case 'Argentina Standard Time':
          timezone[i].name = this.translater.transform('(UTC-03:00)BUENOS_AIRES');
          break;
        case 'SA Eastern Standard Time':
          timezone[i].name = this.translater.transform('(UTC-03:00)CAYENNE_FORTALEZA');
          break;
        case 'Greenland Standard Time':
          timezone[i].name = this.translater.transform('(UTC-03:00)GREENLAND');
          break;
        case 'Montevideo Standard Time':
          timezone[i].name = this.translater.transform('(UTC-03:00)MONTEVIDEO');
          break;
        case 'Bahia Standard Time':
          timezone[i].name = this.translater.transform('(UTC-03:00)SALVADOR');
          break;
        case 'UTC-02':
          timezone[i].name = this.translater.transform('(UTC-02:00)COORDINATED_UNIVERSAL_TIME-02');
          break;
        case 'Mid-Atlantic Standard Time':
          timezone[i].name = this.translater.transform('(UTC-02:00)MID-ATLANTIC-OLD');
          break;
        case 'Azores Standard Time':
          timezone[i].name = this.translater.transform('(UTC-01:00)AZORES');
          break;
        case 'Cape Verde Standard Time':
          timezone[i].name = this.translater.transform('(UTC-01:00)CAPE_VERDE_IS');
          break;
        case 'Morocco Standard Time':
          timezone[i].name = this.translater.transform('(UTC)CASABLANCA');
          break;
        case 'UTC':
          timezone[i].name = this.translater.transform('(UTC)COORDINATED_UNIVERSAL_TIME');
          break;
        case 'GMT Standard Time':
          timezone[i].name = this.translater.transform('(UTC)DUBLIN_EDINBURGH_LISBON_LONDON');
          break;
        case 'Greenwich Standard Time':
          timezone[i].name = this.translater.transform('(UTC)MONROVIA_REYKJAVIK');
          break;
        case 'W. Europe Standard Time':
          timezone[i].name = this.translater.transform(
            '(UTC+01:00)AMSTERDAM_BERLIN_BERN_ROME_STOCKHOLM_VIENNA',
          );
          break;
        case 'Central Europe Standard Time':
          timezone[i].name = this.translater.transform(
            '(UTC+01:00)BELGRADE_BRATISLAVA_BUDAPEST_LJUBLJANA_PRAGUE',
          );
          break;
        case 'Romance Standard Time':
          timezone[i].name = this.translater.transform(
            '(UTC+01:00)BRUSSELS_COPENHAGEN_MADRID_PARIS',
          );
          break;
        case 'Central European Standard Time':
          timezone[i].name = this.translater.transform('(UTC+01:00)SARAJEVO_SKOPJE_WARSAW_ZAGREB');
          break;
        case 'W. Central Africa Standard Time':
          timezone[i].name = this.translater.transform('(UTC+01:00)WEST_CENTRAL_AFRICA');
          break;
        case 'Namibia Standard Time':
          timezone[i].name = this.translater.transform('(UTC+01:00)WINDHOEK');
          break;
        case 'Jordan Standard Time':
          timezone[i].name = this.translater.transform('(UTC+02:00)AMMAN');
          break;
        case 'GTB Standard Time':
          timezone[i].name = this.translater.transform('(UTC+02:00)ATHENS_BUCHAREST');
          break;
        case 'Middle East Standard Time':
          timezone[i].name = this.translater.transform('(UTC+02:00)BEIRUT');
          break;
        case 'Egypt Standard Time':
          timezone[i].name = this.translater.transform('(UTC+02:00)CAIRO');
          break;
        case 'Syria Standard Time':
          timezone[i].name = this.translater.transform('(UTC+02:00)DAMASCUS');
          break;
        case 'E. Europe Standard Time':
          timezone[i].name = this.translater.transform('(UTC+02:00)E_EUROPE');
          break;
        case 'South Africa Standard Time':
          timezone[i].name = this.translater.transform('(UTC+02:00)HARARE_PRETORIA');
          break;
        case 'FLE Standard Time':
          timezone[i].name = this.translater.transform(
            '(UTC+02:00)HELSINKI_KYIV_RIGA_SOFIA_TALLINN_VILNIUS',
          );
          break;
        case 'Turkey Standard Time':
          timezone[i].name = this.translater.transform('(UTC+02:00)ISTANBUL');
          break;
        case 'Israel Standard Time':
          timezone[i].name = this.translater.transform('(UTC+02:00)JERUSALEM');
          break;
        case 'Libya Standard Time':
          timezone[i].name = this.translater.transform('(UTC+02:00)TRIPOLI');
          break;
        case 'Arabic Standard Time':
          timezone[i].name = this.translater.transform('(UTC+03:00)BAGHDAD');
          break;
        case 'Kaliningrad Standard Time':
          timezone[i].name = this.translater.transform('(UTC+03:00)KALININGRAD_MINSK');
          break;
        case 'Arab Standard Time':
          timezone[i].name = this.translater.transform('(UTC+03:00)KUWAIT_RIYADH');
          break;
        case 'E. Africa Standard Time':
          timezone[i].name = this.translater.transform('(UTC+03:00)NAIROBI');
          break;
        case 'Iran Standard Time':
          timezone[i].name = this.translater.transform('(UTC+03:30)TEHRAN');
          break;
        case 'Arabian Standard Time':
          timezone[i].name = this.translater.transform('(UTC+04:00)ABU_DHABI_MUSCAT');
          break;
        case 'Azerbaijan Standard Time':
          timezone[i].name = this.translater.transform('(UTC+04:00)BAKU');
          break;
        case 'Russian Standard Time':
          timezone[i].name = this.translater.transform('(UTC+04:00)MOSCOW_ST_PETERSBURG_VOLGOGRAD');
          break;
        case 'Mauritius Standard Time':
          timezone[i].name = this.translater.transform('(UTC+04:00)PORT_LOUIS');
          break;
        case 'Georgian Standard Time':
          timezone[i].name = this.translater.transform('(UTC+04:00)TBILISI');
          break;
        case 'Caucasus Standard Time':
          timezone[i].name = this.translater.transform('(UTC+04:00)YEREVAN');
          break;
        case 'Afghanistan Standard Time':
          timezone[i].name = this.translater.transform('(UTC+04:30)KABUL');
          break;
        case 'West Asia Standard Time':
          timezone[i].name = this.translater.transform('(UTC+05:00)ASHGABAT_TASHKENT');
          break;
        case 'Pakistan Standard Time':
          timezone[i].name = this.translater.transform('(UTC+05:00)ISLAMABAD_KARACHI');
          break;
        case 'India Standard Time':
          timezone[i].name = this.translater.transform(
            '(UTC+05:30)CHENNAI_KOLKATA_MUMBAI_NEW_DELHI',
          );
          break;
        case 'Sri Lanka Standard Time':
          timezone[i].name = this.translater.transform('(UTC+05:30)SRI_JAYAWARDENEPURA');
          break;
        case 'Nepal Standard Time':
          timezone[i].name = this.translater.transform('(UTC+05:45)KATHMANDU');
          break;
        case 'Central Asia Standard Time':
          timezone[i].name = this.translater.transform('(UTC+06:00)ASTANA');
          break;
        case 'Bangladesh Standard Time':
          timezone[i].name = this.translater.transform('(UTC+06:00)DHAKA');
          break;
        case 'Ekaterinburg Standard Time':
          timezone[i].name = this.translater.transform('(UTC+06:00)EKATERINBURG');
          break;
        case 'Myanmar Standard Time':
          timezone[i].name = this.translater.transform('(UTC+06:30)YANGON(RANGOON)');
          break;
        case 'SE Asia Standard Time':
          timezone[i].name = this.translater.transform('(UTC+07:00)BANGKOK_HANOI_JAKARTA');
          break;
        case 'N. Central Asia Standard Time':
          timezone[i].name = this.translater.transform('(UTC+07:00)NOVOSIBIRSK');
          break;
        case 'China Standard Time':
          timezone[i].name = this.translater.transform(
            '(UTC+08:00)BEIJING_CHONGQING_HONG_KONG_URUMQI',
          );
          break;
        case 'North Asia Standard Time':
          timezone[i].name = this.translater.transform('(UTC+08:00)KRASNOYARSK');
          break;
        case 'Singapore Standard Time':
          timezone[i].name = this.translater.transform('(UTC+08:00)KUALA_LUMPUR_SINGAPORE');
          break;
        case 'W. Australia Standard Time':
          timezone[i].name = this.translater.transform('(UTC+08:00)PERTH');
          break;
        case 'Taipei Standard Time':
          timezone[i].name = this.translater.transform('(UTC+08:00)TAIPEI');
          break;
        case 'Ulaanbaatar Standard Time':
          timezone[i].name = this.translater.transform('(UTC+08:00)ULAANBAATAR');
          break;
        case 'North Asia East Standard Time':
          timezone[i].name = this.translater.transform('(UTC+09:00)IRKUTSK');
          break;
        case 'Tokyo Standard Time':
          timezone[i].name = this.translater.transform('(UTC+09:00)OSAKA_SAPPORO_TOKYO');
          break;
        case 'Korea Standard Time':
          timezone[i].name = this.translater.transform('(UTC+09:00)SEOUL');
          break;
        case 'Cen. Australia Standard Time':
          timezone[i].name = this.translater.transform('(UTC+09:30)ADELAIDE');
          break;
        case 'AUS Central Standard Time':
          timezone[i].name = this.translater.transform('(UTC+09:30)DARWIN');
          break;
        case 'E. Australia Standard Time':
          timezone[i].name = this.translater.transform('(UTC+10:00)BRISBANE');
          break;
        case 'AUS Eastern Standard Time':
          timezone[i].name = this.translater.transform('(UTC+10:00)CANBERRA_MELBOURNE_SYDNEY');
          break;
        case 'West Pacific Standard Time':
          timezone[i].name = this.translater.transform('(UTC+10:00)GUAM_PORT_MORESBY');
          break;
        case 'Tasmania Standard Time':
          timezone[i].name = this.translater.transform('(UTC+10:00)HOBART');
          break;
        case 'Yakutsk Standard Time':
          timezone[i].name = this.translater.transform('(UTC+10:00)YAKUTSK');
          break;
        case 'Central Pacific Standard Time':
          timezone[i].name = this.translater.transform('(UTC+11:00)SOLOMON_IS_NEW_CALEDONIA');
          break;
        case 'Vladivostok Standard Time':
          timezone[i].name = this.translater.transform('(UTC+11:00)VLADIVOSTOK');
          break;
        case 'New Zealand Standard Time':
          timezone[i].name = this.translater.transform('(UTC+12:00)AUCKLAND_WELLINGTON');
          break;
        case 'UTC+12':
          timezone[i].name = this.translater.transform('(UTC+12:00)COORDINATED_UNIVERSAL_TIME+12');
          break;
        case 'Fiji Standard Time':
          timezone[i].name = this.translater.transform('(UTC+12:00)FIJI');
          break;
        case 'Magadan Standard Time':
          timezone[i].name = this.translater.transform('(UTC+12:00)MAGADAN');
          break;
        case 'Kamchatka Standard Time':
          timezone[i].name = this.translater.transform('(UTC+12:00)PETROPAVLOVSK-KAMCHATSKY-OLD');
          break;
        case 'Tonga Standard Time':
          timezone[i].name = this.translater.transform('(UTC+13:00)NUKU_ALOFA');
          break;
        case 'Samoa Standard Time':
          timezone[i].name = this.translater.transform('(UTC+13:00)SAMOA');
          break;
        case 'Line Islands Standard Time':
          timezone[i].name = this.translater.transform('(UTC+14:00)KIRITIMATI_ISLAND');
          break;
        default:
          break;
      }
    }
  }

  qrisStatusTranslate(qris) {
    for (let i = 0; i < qris.length; i++) {
      switch (qris[i].id) {
        case '0':
          qris[i].name = this.translater.transform(
            'NO_PROVIDER_IS_ELIGIBLE_BUT_DOES_NOT_PARTICIPATE_IN_QRIS',
          );
          break;
        case '1':
          qris[i].name = this.translater.transform('YES_PROVIDER_DOES_PARTICIPATE_IN_A_QRIS');
          break;
        case '7':
          // tslint:disable-next-line: max-line-length
          qris[i].name = this.translater.transform(
            'THE_STATE_HAS_AN_OPERATING_QRIS_IN_THE_PROVIDERS_AREA_BUT_THE_PROVIDER_IS_NOT_ELIGIBLE_TO_PARTICIPATE',
          );
          break;
        case '8':
          qris[i].name = this.translater.transform(
            'THE_STATE_DOES_NOT_HAVE_AN_OPERATING_QRIS_IN_THE_PROVIDERS_AREA',
          );
          break;
        default:
          break;
      }
    }
  }

  accrsTranslate(accr) {
    for (let i = 0; i < accr.length; i++) {
      switch (accr[i].id) {
        case '0':
          accr[i].name = this.translater.transform('NO');
          break;
        case '1':
          accr[i].name = this.translater.transform('YES_NATIONAL_ACCREDITATION');
          break;
        case '2':
          accr[i].name = this.translater.transform('YES_STATE_ACCREDITATION');
          break;
        case '3':
          accr[i].name = this.translater.transform(
            'YES_OTHER_ACCREDITATION(NOT_NATIONAL_OR_STATE_LEVEL)',
          );
          break;
        case '4':
          accr[i].name = this.translater.transform('YES_LEVEL_TYPE_OF_ACCREDITATION_UNAVAILABLE');
          break;
        case '9':
          accr[i].name = this.translater.transform('NA_INFORMATION_CURRENTLY_UNAVAILABLE');
          break;
        default:
          break;
      }
    }
  }
}
